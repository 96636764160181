"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyService = void 0;
const key_map_service_1 = require("./key-map.service");
const km = new key_map_service_1.KeyMapService();
class KeyService {
  shortToLong(shortKeyJson) {
    if (shortKeyJson === null) {
      return {};
    }
    const obj = {};
    Object.keys(shortKeyJson).forEach(key => {
      let objKey;
      if (km.enumToLong[km.shortToEnum[key]]) {
        objKey = km.enumToLong[km.shortToEnum[key]];
      } else {
        objKey = key;
      }
      if (Array.isArray(shortKeyJson[key])) {
        obj[objKey] = [];
        shortKeyJson[key].forEach(element => {
          if (typeof element === "object" && element !== null || Array.isArray(element)) {
            obj[objKey].push(this.shortToLong(element));
          } else {
            obj[objKey].push(element);
          }
        });
      } else if (typeof shortKeyJson[key] === "object" && shortKeyJson[key] !== null) {
        obj[objKey] = this.shortToLong(shortKeyJson[key]);
      } else {
        obj[objKey] = shortKeyJson[key];
      }
    });
    return obj;
  }
  longKeyToShortKey(longKey) {
    return km.enumToShort[km.longToEnum[longKey]];
  }
}
exports.KeyService = KeyService;