"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RelayResultTransformer = void 0;
class RelayResultTransformer {
  static fromLiveRunHeatResult(result, event) {
    var _a, _b, _c, _d, _e, _f, _g;
    const relayResult = this.getBlankRelayResult();
    // Event Details
    const eventID = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    relayResult.relayEvent.id = isNaN(eventID) ? 0 : eventID;
    relayResult.relayEvent.abbrev = event.abbrev;
    relayResult.relayEvent.shortName = event.name;
    relayResult.relayEvent.roundId = event.roundId;
    // Performance Details
    relayResult.mark = result.mark;
    relayResult.rankings = (_b = result.rankings) !== null && _b !== void 0 ? _b : [];
    relayResult.records = (_c = result.records) !== null && _c !== void 0 ? _c : [];
    relayResult.seasonRecord = (_d = result.seasonRecord) !== null && _d !== void 0 ? _d : '';
    relayResult.timeStandards = (_e = result.timeStandards) !== null && _e !== void 0 ? _e : [];
    relayResult.place = result.place;
    relayResult.seasonRecord = (_f = result.seasonRecord) !== null && _f !== void 0 ? _f : '';
    // Team Details
    const teamCode = ((_g = result.teamName) === null || _g === void 0 ? void 0 : _g.length) > 0 ? ' - ' + result.teamName : '';
    relayResult.team.fullName = result.name + teamCode;
    relayResult.team.logo = result.logo;
    // Other Details
    relayResult.id = -1;
    if (result.athleticLiveTeamId) {
      if (!isNaN(+result.athleticLiveTeamId)) {
        relayResult.team.id = +result.athleticLiveTeamId;
      }
    }
    if (result.athleticNetId) {
      if (!isNaN(+result.athleticNetId)) {
        relayResult.team.athleticNetId = +result.athleticNetId;
      }
    }
    if (result.imageUrl) {
      relayResult.imageUrl = result.imageUrl;
    }
    if (result.imageThumbnail) {
      relayResult.imageThumbnail = result.imageThumbnail;
    }
    if (result.imageThumbnailIsVideo) {
      relayResult.imageThumbnailIsVideo = result.imageThumbnailIsVideo;
    }
    return relayResult;
  }
  static fromLiveRunEntry(entry, event) {
    var _a, _b, _c, _d, _e, _f, _g;
    const relayResult = this.getBlankRelayResult();
    // Event Details
    const eventID = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    relayResult.relayEvent.id = isNaN(eventID) ? 0 : eventID;
    relayResult.relayEvent.abbrev = event.abbrev;
    relayResult.relayEvent.shortName = event.name;
    relayResult.relayEvent.roundId = event.roundId;
    // Performance Details
    relayResult.rankings = (_b = entry.rankings) !== null && _b !== void 0 ? _b : [];
    relayResult.records = (_c = entry.records) !== null && _c !== void 0 ? _c : [];
    relayResult.seasonRecord = (_d = entry.seasonRecord) !== null && _d !== void 0 ? _d : '';
    relayResult.timeStandards = (_e = entry.timeStandards) !== null && _e !== void 0 ? _e : [];
    relayResult.place = entry.lane;
    relayResult.seasonRecord = (_f = entry.seasonRecord) !== null && _f !== void 0 ? _f : '';
    // Team Details
    const teamCode = ((_g = entry.teamName) === null || _g === void 0 ? void 0 : _g.length) > 0 ? ' - ' + entry.teamName : '';
    relayResult.team.fullName = entry.name + teamCode;
    relayResult.team.logo = entry.logo;
    // Other Details
    relayResult.id = -1;
    if (entry.athleticLiveTeamId) {
      if (!isNaN(+entry.athleticLiveTeamId)) {
        relayResult.team.id = +entry.athleticLiveTeamId;
      }
    }
    if (entry.athleticNetId) {
      if (!isNaN(+entry.athleticNetId)) {
        relayResult.team.athleticNetId = +entry.athleticNetId;
      }
    }
    return relayResult;
  }
  static fromSplitGridAthlete(result, event) {
    var _a, _b, _c, _d, _e, _f, _g;
    const relayResult = this.getBlankRelayResult();
    // Event Details
    const eventID = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    relayResult.relayEvent.id = isNaN(eventID) ? 0 : eventID;
    relayResult.relayEvent.abbrev = event.abbrev;
    relayResult.relayEvent.shortName = event.name;
    relayResult.relayEvent.roundId = event.roundId;
    // Performance Details
    relayResult.mark = result.mark;
    relayResult.rankings = (_b = result.rankings) !== null && _b !== void 0 ? _b : [];
    relayResult.records = (_c = result.records) !== null && _c !== void 0 ? _c : [];
    relayResult.seasonRecord = (_d = result.seasonRecord) !== null && _d !== void 0 ? _d : '';
    relayResult.timeStandards = (_e = result.timeStandards) !== null && _e !== void 0 ? _e : [];
    relayResult.place = result.place;
    relayResult.seasonRecord = (_f = result.seasonRecord) !== null && _f !== void 0 ? _f : '';
    // Team Details
    const teamCode = ((_g = result.teamName) === null || _g === void 0 ? void 0 : _g.length) > 0 ? ' - ' + result.teamName : '';
    relayResult.team.fullName = result.name + teamCode;
    relayResult.team.logo = result.logo;
    // Other Details
    relayResult.id = -1;
    if (result.athleticLiveTeamId) {
      if (!isNaN(+result.athleticLiveTeamId)) {
        relayResult.team.id = +result.athleticLiveTeamId;
      }
    }
    if (result.athleticNetId) {
      if (!isNaN(+result.athleticNetId)) {
        relayResult.team.athleticNetId = +result.athleticNetId;
      }
    }
    if (result.imageUrl) {
      relayResult.imageUrl = result.imageUrl;
    }
    if (result.imageThumbnail) {
      relayResult.imageThumbnail = result.imageThumbnail;
    }
    return relayResult;
  }
  static getBlankRelayResult() {
    return {
      seasonRecord: '',
      entityRecordNote: '',
      fullRaceVideoModel: null,
      heatPlace: '',
      heatNumber: 0,
      id: 0,
      imageThumbnail: '',
      imageThumbnailIsVideo: false,
      imageUrl: '',
      integerMark: 0,
      mark: '',
      notes: '',
      place: '',
      points: 0,
      rankings: [],
      records: [],
      relayDesignator: '',
      relayEvent: {
        abbrev: '',
        athleticNetEventId: 0,
        advanceNotes: '',
        cancelled: false,
        customName: '',
        customNote: '',
        division: {
          id: 0,
          meetId: 0,
          name: ''
        },
        eventCategory: '',
        eventNotes: '',
        eventStatus: '',
        fontId: '',
        facebookShareUrl: '',
        finalResultsUploadedAt: '',
        gender: '',
        genderLabel: '',
        heat: {},
        hasEntries: false,
        hasLogos: false,
        hasHeats: false,
        hasHorizontalSeries: false,
        headerNotes: '',
        hasPoints: false,
        hasResults: false,
        hasResultHeats: false,
        hasTopFinisherImages: false,
        hasVerticalSeries: false,
        hasWindReadings: false,
        hasYears: false,
        id: 0,
        isFinal: false,
        isFieldRunRelay: false,
        meetId: 0,
        name: '',
        nameAmplifiers: '',
        number: 0,
        primaryEventGroup: '',
        protestEndDate: '',
        protestMgmt: false,
        protestEndDateMs: 0,
        resultHeatNumbers: [],
        rawResultUrl: '',
        round: '',
        roundFinalAliveId: 0,
        roundPrelimAliveId: 0,
        roundQuarterAliveId: 0,
        roundSemiAliveId: 0,
        roundId: '',
        roundName: '',
        roundShort: '',
        resultYears: [],
        date: '',
        session: {
          dateString: '',
          id: 0,
          meetId: 0,
          name: '',
          number: 0
        },
        summaryGroup: '',
        sortKey: 0,
        shortName: '',
        sponsor: '',
        sessionSortOrder: 0,
        topFinisherImageUrls: {},
        hasSplits: false,
        twitterShareUrl: '',
        updatedAt: '',
        summaryName: '',
        urlSlug: '',
        wind: '',
        isXc: false
      },
      relayMembers: [],
      relayNames: '',
      resultOrder: 0,
      relayRecord: {
        dateString: '',
        holder: '',
        id: 0,
        mark: '',
        meetId: 0,
        name: '',
        relayHolders: '',
        sortOrder: 0,
        symbol: ''
      },
      relayRunSplits: [],
      relayTimeStandard: {
        id: 0,
        mark: '',
        meetId: 0,
        name: '',
        sortOrder: 0,
        symbol: ''
      },
      seed: '',
      team: {
        athleticNetLogo: '',
        athleticNetId: 0,
        fullName: '',
        hasBibList: false,
        hasEntries: false,
        hasIndividualEntries: false,
        hasIndividualResults: false,
        hasResults: false,
        hasRelayEntries: false,
        hasRelayResults: false,
        id: 0,
        logo: '',
        meetId: 0,
        name: '',
        isXc: 0
      },
      timeStandards: null,
      validMark: 0,
      wind: ''
    };
  }
}
exports.RelayResultTransformer = RelayResultTransformer;