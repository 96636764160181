"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IndividualResultTransformer = void 0;
class IndividualResultTransformer {
  // from tmTrackResultList.js
  static fromLiveRunHeatResult(result, event) {
    var _a, _b, _c, _d, _e, _f;
    const individualResult = this.getBlankIndividualResult();
    // Event Details
    const eventID = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    individualResult.individualEvent.id = isNaN(eventID) ? 0 : eventID;
    individualResult.individualEvent.abbrev = event.abbrev;
    individualResult.individualEvent.shortName = event.name;
    individualResult.individualEvent.roundId = event.roundId;
    // Performance Details
    individualResult.mark = result.mark;
    individualResult.personalRecord = (_b = result.personalRecord) !== null && _b !== void 0 ? _b : '';
    individualResult.place = result.place;
    individualResult.rankings = (_c = result.rankings) !== null && _c !== void 0 ? _c : [];
    individualResult.records = (_d = result.records) !== null && _d !== void 0 ? _d : [];
    individualResult.seasonRecord = (_e = result.seasonRecord) !== null && _e !== void 0 ? _e : '';
    individualResult.timeStandards = (_f = result.timeStandards) !== null && _f !== void 0 ? _f : [];
    // Athlete Details
    individualResult.athlete.compNum = result.compNum;
    individualResult.athlete.name = result.name;
    individualResult.athlete.team.fullName = result.teamName;
    individualResult.athlete.team.logo = result.logo;
    // Other Details
    individualResult.fieldAppVideoModels = [];
    individualResult.fieldAppVideos = [];
    individualResult.hasFieldAppVideos = 0;
    individualResult.id = -1;
    if (result.athleticLiveId) {
      if (!isNaN(+result.athleticLiveId)) {
        individualResult.athlete.athleticLiveId = +result.athleticLiveId;
      }
    }
    if (result.athleticNetId) {
      if (!isNaN(+result.athleticNetId)) {
        individualResult.athlete.athleticNetId = +result.athleticNetId;
      }
    }
    if (result.imageUrl) {
      individualResult.imageUrl = result.imageUrl;
    }
    if (result.imageThumbnail) {
      individualResult.imageThumbnail = result.imageThumbnail;
    }
    if (result.imageThumbnailIsVideo) {
      individualResult.imageThumbnailIsVideo = result.imageThumbnailIsVideo;
    }
    return individualResult;
  }
  // from tmTrackSplitLiveGrid.js
  static fromSplitGridAthlete(result, event) {
    var _a, _b, _c, _d, _e, _f;
    const individualResult = this.getBlankIndividualResult();
    individualResult.athlete.compNum = result.compNum;
    individualResult.athlete.name = result.name;
    individualResult.athlete.team.fullName = result.teamName;
    individualResult.athlete.team.logo = result.logo;
    individualResult.hasFieldAppVideos = 0;
    individualResult.id = -1;
    individualResult.individualEvent.abbrev = event.abbrev;
    individualResult.individualEvent.id = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    individualResult.individualEvent.shortName = event.name;
    individualResult.individualEvent.roundId = event.roundId;
    individualResult.mark = result.mark;
    individualResult.personalRecord = (_b = result.personalRecord) !== null && _b !== void 0 ? _b : '';
    individualResult.place = result.place;
    individualResult.rankings = (_c = result.rankings) !== null && _c !== void 0 ? _c : [];
    individualResult.records = (_d = result.records) !== null && _d !== void 0 ? _d : [];
    individualResult.seasonRecord = (_e = result.seasonRecord) !== null && _e !== void 0 ? _e : '';
    individualResult.timeStandards = (_f = result.timeStandards) !== null && _f !== void 0 ? _f : [];
    if (result.athleticLiveId) {
      individualResult.athlete.athleticLiveId = +result.athleticLiveId;
    }
    if (result.athleticNetId) {
      individualResult.athlete.athleticNetId = +result.athleticNetId;
    }
    if (result.imageUrl) {
      individualResult.imageUrl = result.imageUrl;
    }
    if (result.imageThumbnail) {
      individualResult.imageThumbnail = result.imageThumbnail;
    }
    return individualResult;
  }
  static fromLiveVerticalFieldStandingsItem(result, event) {
    var _a, _b, _c, _d, _e, _f;
    const individualResult = this.getBlankIndividualResult();
    individualResult.athlete.compNum = result.id;
    individualResult.athlete.name = result.name;
    individualResult.athlete.team.fullName = result.teamName;
    individualResult.athlete.team.logo = result.logo;
    individualResult.hasFieldAppVideos = 0;
    individualResult.fieldAppVideoModels = [];
    individualResult.fieldAppVideos = [];
    individualResult.id = -1;
    individualResult.individualEvent.abbrev = event.abbrev;
    individualResult.individualEvent.id = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    individualResult.individualEvent.shortName = event.name;
    individualResult.personalRecord = (_b = result.personalRecord) !== null && _b !== void 0 ? _b : '';
    individualResult.place = result.place.toString();
    individualResult.rankings = (_c = result.rankings) !== null && _c !== void 0 ? _c : [];
    individualResult.records = (_d = result.records) !== null && _d !== void 0 ? _d : [];
    individualResult.seasonRecord = (_e = result.seasonRecord) !== null && _e !== void 0 ? _e : '';
    individualResult.timeStandards = (_f = result.timeStandards) !== null && _f !== void 0 ? _f : [];
    individualResult.verticalSeries = [];
    if (result.fieldAppVideos && result.fieldAppVideos.length > 0) {
      individualResult.hasFieldAppVideos = 1;
      individualResult.fieldAppVideos = result.fieldAppVideos.filter(function (a) {
        return a !== '0';
      });
      if (Array.isArray(result.fieldAppVideoModels)) {
        individualResult.fieldAppVideoModels = result.fieldAppVideoModels;
      } else {
        const keys = Object.keys(result.fieldAppVideoModels);
        for (let i = 0, len = keys.length; i < len; i++) {
          individualResult.fieldAppVideoModels.push(result.fieldAppVideoModels[keys[i]]);
        }
      }
    }
    if (result.athleticLiveId) {
      individualResult.athlete.athleticLiveId = +result.athleticLiveId;
    }
    if (result.athleticNetId) {
      individualResult.athlete.athleticNetId = +result.athleticNetId;
    }
    if (result.bestMark) {
      individualResult.mark = result.bestMark.height;
      individualResult.conversion = result.bestMark.conversion;
    }
    if (result.fieldSeries) {
      individualResult.verticalSeries = result.fieldSeries;
    }
    return individualResult;
  }
  // from HorizontalFieldSBCtrl.js
  // from tmSbHorizSeries.js
  // from FieldSBDashboardCtrl.js
  static fromLiveHorizontalFieldStandingsItem(result, event) {
    var _a, _b, _c, _d, _e, _f;
    const individualResult = this.getBlankIndividualResult();
    individualResult.athlete.compNum = result.id;
    individualResult.athlete.name = result.name;
    individualResult.athlete.team.fullName = result.teamName;
    individualResult.athlete.team.logo = result.logo;
    individualResult.hasFieldAppVideos = 0;
    individualResult.fieldAppVideoModels = [];
    individualResult.fieldAppVideos = [];
    individualResult.horizontalSeries = [];
    individualResult.id = -1;
    individualResult.individualEvent.abbrev = event.abbrev;
    individualResult.individualEvent.id = (_a = +event.masterEventId) !== null && _a !== void 0 ? _a : 0;
    individualResult.individualEvent.shortName = event.name;
    individualResult.personalRecord = (_b = result.personalRecord) !== null && _b !== void 0 ? _b : '';
    individualResult.place = result.place.toString();
    individualResult.rankings = (_c = result.rankings) !== null && _c !== void 0 ? _c : [];
    individualResult.records = (_d = result.records) !== null && _d !== void 0 ? _d : [];
    individualResult.seasonRecord = (_e = result.seasonRecord) !== null && _e !== void 0 ? _e : '';
    individualResult.timeStandards = (_f = result.timeStandards) !== null && _f !== void 0 ? _f : [];
    if (result.fieldAppVideos && result.fieldAppVideos.length > 0) {
      individualResult.hasFieldAppVideos = 1;
      individualResult.fieldAppVideos = result.fieldAppVideos.filter(function (a) {
        return a !== '0';
      });
      individualResult.fieldAppVideoModels = result.fieldAppVideoModels;
    }
    if (result.athleticLiveId) {
      individualResult.athlete.athleticLiveId = +result.athleticLiveId;
    }
    if (result.athleticNetId) {
      individualResult.athlete.athleticNetId = +result.athleticNetId;
    }
    if (result.bestMark) {
      individualResult.mark = result.bestMark.mark;
      individualResult.conversion = result.bestMark.conversion;
    }
    if (result.fieldSeries) {
      // Live and final got mixed up.
      // Need to transform live into final
      result.fieldSeries.forEach((seriesItem, idx) => {
        result.fieldSeries[idx].number = +seriesItem.attempt;
        result.fieldSeries[idx].attempt = seriesItem.mark;
      });
      individualResult.horizontalSeries = result.fieldSeries;
    }
    return individualResult;
  }
  // from tmIndByEventResults.js
  // from tmIndByTeamByEventResults.js
  // from tmIndHorizSeries.js
  // from tmIndResultSplits.js
  // from tmIndVertSeries.js
  // from tmIndTeamResults.js
  // static fromIndividualResult(
  //   result: IndividualResult,
  //   event: CommonEvent
  // ): AthleteModalResult {
  //   const athleteModalResult: AthleteModalResult = {
  //     id: result.id,
  //     place: result.place,
  //     athlete: {
  //       compNum: result.athlete.compNum,
  //       name: result.athlete.name,
  //       team: {
  //         fullName: result.athlete.team.fullName,
  //         logo: result.athlete.team.logo,
  //       },
  //     },
  //     individualEvent: {
  //       abbrev: event.abbrev,
  //       id: event.id ?? null,
  //       shortName: event.shortName,
  //     },
  //     hasFieldAppVideos: result.hasFieldAppVideos === 1,
  //     fieldAppVideos: result.hasFieldAppVideos ? result.fieldAppVideos : [],
  //     fieldAppVideoModels: result.hasFieldAppVideos ? result.fieldAppVideoModels : [],
  //     horizontalSeries: [],
  //   }
  //
  //   if (result.athlete.id) {
  //     athleteModalResult.athlete.athleticLiveId = result.athlete.id.toString();
  //   }
  //
  //   if (result.athlete.athleticNetId) {
  //     athleteModalResult.athlete.athleticNetId = result.athlete.athleticNetId.toString();
  //   }
  //
  //   if (result.mark) {
  //     athleteModalResult.mark = result.mark;
  //   }
  //
  //   if (result.conversion) {
  //     athleteModalResult.conversion = result.conversion;
  //   }
  //
  //   if (result.verticalSeries) {
  //     athleteModalResult.verticalSeries = result.verticalSeries;
  //   }
  //
  //   if (result.horizontalSeries) {
  //     athleteModalResult.horizontalSeries = result.horizontalSeries;
  //   }
  //
  //   return athleteModalResult;
  // }
  static fromTeamDetailIndividualResult(result, teamDetail) {
    result.athlete.team = teamDetail;
    return result;
  }
  static fromTeamDetailByEventIndividualResult(result, teamDetail, event) {
    result.athlete.team = teamDetail;
    return result;
  }
  // from MeetWinnersController.js
  static fromIndividualWinner(result) {
    const individualResult = this.getBlankIndividualResult();
    individualResult.id = result.event.id;
    individualResult.place = '1';
    individualResult.athlete.compNum = result.athlete.id.toString();
    individualResult.athlete.name = result.athlete.name;
    individualResult.athlete.team.fullName = result.athlete.team.fullName;
    individualResult.athlete.team.logo = result.athlete.team.logo;
    individualResult.individualEvent.abbrev = result.event.shortName;
    individualResult.individualEvent.id = result.event.id;
    individualResult.individualEvent.shortName = result.event.shortName;
    if (result.mark) {
      individualResult.mark = result.mark;
    }
    if (result.conversion) {
      individualResult.conversion = result.conversion;
    }
    return individualResult;
  }
  // from RecordDetailController.js
  // from TimeStandardDetailController.js
  static fromIndividualRecord(result, record) {
    const individualResult = this.getBlankIndividualResult();
    individualResult.id = result.id;
    individualResult.place = '1';
    individualResult.athlete.compNum = result.athlete.id.toString();
    individualResult.athlete.name = result.athlete.name;
    individualResult.athlete.team.fullName = result.athlete.team.fullName;
    individualResult.athlete.team.logo = result.athlete.team.logo;
    individualResult.individualEvent.abbrev = record.event.shortName;
    individualResult.individualEvent.id = record.event.id;
    individualResult.individualEvent.shortName = record.event.shortName;
    if (result.mark) {
      individualResult.mark = result.mark;
    }
    if (result.conversion) {
      individualResult.conversion = result.conversion;
    }
    return individualResult;
  }
  static getBlankIndividualResult() {
    return {
      athlete: {
        age: '',
        athleticNetId: 0,
        athleticLiveId: 0,
        compNum: '',
        firstName: '',
        photoUrl: '',
        gender: '',
        id: 0,
        lastName: '',
        meetId: 0,
        name: '',
        team: {
          athleticNetLogo: '',
          athleticNetId: 0,
          fullName: '',
          hasBibList: false,
          hasEntries: false,
          hasIndividualEntries: false,
          hasIndividualResults: false,
          hasResults: false,
          hasRelayEntries: false,
          hasRelayResults: false,
          id: 0,
          logo: '',
          meetId: 0,
          name: '',
          isXc: 0
        },
        isXc: 0,
        year: ''
      },
      ageGradeMark: '',
      ageGradePct: '',
      avgKm: '',
      avgMile: '',
      bestMark: 0,
      conversion: '',
      combinedPoints: 0,
      seasonRecord: '',
      entityRecordNote: '',
      hasFieldAppVideos: 0,
      hasFinishLineImages: 0,
      hasFinishLineVideos: 0,
      heatPlace: '',
      heatNumber: 0,
      horizontalSeries: [],
      id: 0,
      integerMark: 0,
      individualEvent: {
        abbrev: '',
        athleticNetEventId: 0,
        advanceNotes: '',
        cancelled: false,
        customName: '',
        customNote: '',
        division: {
          id: 0,
          meetId: 0,
          name: ''
        },
        eventCategory: '',
        eventNotes: '',
        eventStatus: '',
        fontId: '',
        facebookShareUrl: '',
        finalResultsUploadedAt: '',
        gender: '',
        genderLabel: '',
        heat: {},
        hasEntries: false,
        hasLogos: false,
        hasHeats: false,
        hasHorizontalSeries: false,
        headerNotes: '',
        hasPoints: false,
        hasResults: false,
        hasResultHeats: false,
        hasTopFinisherImages: false,
        hasVerticalSeries: false,
        hasWindReadings: false,
        hasYears: false,
        id: 0,
        isFinal: false,
        isFieldRunRelay: false,
        multiAgeGroupTitle: '',
        meetId: 0,
        name: '',
        nameAmplifiers: '',
        number: 0,
        primaryEventGroup: '',
        protestEndDate: '',
        protestMgmt: false,
        protestEndDateMs: 0,
        resultHeatNumbers: [],
        rawResultUrl: '',
        round: '',
        roundId: '',
        roundName: '',
        roundShort: '',
        roundFinalAliveId: 0,
        roundPrelimAliveId: 0,
        roundQuarterAliveId: 0,
        roundSemiAliveId: 0,
        resultYears: [],
        date: '',
        session: {
          dateString: '',
          id: 0,
          meetId: 0,
          name: '',
          number: 0
        },
        summaryGroup: '',
        sortKey: 0,
        shortName: '',
        sponsor: '',
        sessionSortOrder: 0,
        topFinisherImageUrls: {},
        hasSplits: false,
        twitterShareUrl: '',
        updatedAt: '',
        summaryName: '',
        urlSlug: '',
        wind: '',
        isXc: false
      },
      individualRecord: {
        dateString: '',
        holder: '',
        id: 0,
        mark: '',
        meetId: 0,
        name: '',
        sortOrder: 0,
        symbol: ''
      },
      individualRunSplits: [],
      individualTimeStandard: {
        id: 0,
        mark: '',
        meetId: 0,
        name: '',
        sortOrder: 0,
        symbol: ''
      },
      ith: '',
      imageThumbnail: '',
      imageThumbnailIsVideo: false,
      imageUrl: '',
      mark: '',
      med: {
        media: '',
        title: '',
        type: ''
      },
      notes: '',
      place: '',
      personalRecord: '',
      points: 0,
      rankings: [],
      reacTime: '',
      records: [],
      resultOrder: 0,
      seed: '',
      timeStandards: [],
      validMark: 0,
      verticalSeries: [],
      wind: '',
      fieldAppVideos: [],
      fieldAppVideoModels: []
    };
  }
}
exports.IndividualResultTransformer = IndividualResultTransformer;